import { useEffect, useState } from "react";
import Baner from "../components/ui/baner";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import Layout from "../components/ui/layout";
import Bg from "../images/form_bg.png"
import { useTranslation } from "react-i18next";
import axios from '../api/without_user_token'


export default function Formalities() {
    const [content, setContent] = useState()
    const { t, i18n } = useTranslation('global');

    const fetchContent = () => {
        axios.get('get_all_condition_guide', {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.status === 200) {
                const filtered = {
                    en: response.data?.en.filter(item => item.type === "GUIDE"),
                    fr: response.data?.fr.filter(item => item.type === "GUIDE"),
                    ar: response.data?.ar?.filter(item => item.type === "GUIDE"),
                    pt: response.data?.pt?.filter(item => item.type === "GUIDE"),
                }
                setContent(filtered)
            }
        }).catch((err) => {
            console.log(err.message)
        })
    }

    useEffect(() => {
        fetchContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        document.title = t('proccess.title');
    }, [t]);
    
    const formalities = [
        { title: t("formalities.trader_status"), content: <Typography sx={{ textAlign: "justify" }}>{t("formalities.trader_status_content")}</Typography> },
        { title: t("formalities.cdt_practice"), content: <Typography sx={{ textAlign: "justify" }}> {t("formalities.cdt_prtc_content")} </Typography> },
        {
            title: t("formalities.abi_pro_in"), content: <Box> <Typography sx={{ textAlign: "justify" }}>
                {t("formalities.lgl_cpcty")}
            </Typography>
                <Typography>
                    {t("formalities.icptbl_fnt_intro")}
                </Typography>
                <ul>
                    <li>
                        {t("formalities.public_employees")}
                    </li>
                    <li>
                        {t("formalities.mnstrl_offcrs")}
                    </li>
                    <li>
                        {t("formalities.acctnt")}
                    </li>
                    <li>
                        {t("formalities.profss_prohbtg")}
                    </li>
                </ul>
                <Typography>
                    {t("formalities.ppl_sntnd")}
                </Typography>
                <ul>
                    <li>
                        {t("formalities.ban_crt")}
                    </li>
                    <li>
                        {t("formalities.ban_by_pro_crt")}
                    </li>
                    <li>
                        {t("formalities.final_sentence")}
                    </li>
                </ul>
                <Typography>
                    {t("formalities.pro_cbnt_frbdn")}
                </Typography>
                <Typography>
                    {t("formalities.tmprry_ban_more_5yrs")}
                </Typography>
                <Typography>
                    {t("formalities.rsqt_admsblty")}
                </Typography>
                <Typography>
                    {t("formalities.sctns_oppsblty")}
                </Typography>
            </Box>
        },
    
        {
            title: t("formalities.oblgtns_imprtr_xprtr"), content: <Box>
                <Typography sx={{ textAlign: "justify" }}>
                    {t("formalities.obligtns_intro")}
                </Typography>
                <ul>
                    <li>
                        {t("formalities.reqr_info")}
                    </li>
                    <li>
                        {t("formalities.indqr_rsgmt_frnr")}
                    </li>
                    <li>
                        {t("formalities.dsgnr_prsn_tn_frnr_rsgnmts")}
                    </li>
                    <li>
                        {t("formalities.crcstcs_rsgnmts")}
                    </li>
                    <li>
                        {t("formalities.fx_dl_mdlt_frntr")}
                    </li>
                </ul>
                <Typography>
                    {t("formalities.cdtn_vl_aernfs_cvl_mltr")}
                </Typography>
                <Typography>
                    {t("formalities.pr_cdctr_mrchds_arrv_brr_dn")}
                </Typography>
                <ul>
                    <li>
                        {t("formalities.proprio_mrchds")}
                    </li>
                    <li>
                        {t("formalities.pys_dprt_myn_trsprt")}
                    </li>
                    <li>
                        {t("formalities.pys_dstntn_cas_echt")}
                    </li>
                    <li>
                        {t("formalities.nmr_imtrclt_vhcl")}
                    </li>
                    <li>
                        {t("formalities.nmr_id_ctnr_cas_echt")}
                    </li>
                    <li>
                        {t("formalities.mrq_nmr_cls")}
                    </li>
                    <li>
                        {t("formalities.nmbr_ntr_mrchds")}
                    </li>
                    <li>
                        {t("formalities.mrq_nmr_scllmnts_dnr_cas_echt")}
                    </li>
                    <li>
                        {t("formalities.pds_brt")}
                    </li>
                </ul>
                <Typography sx={{ textAlign: 'justify' }}>
                    {t("formalities.arnf_prcrs_itrntnl")}
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    {t("formalities.pvt_pas_psr_arprts_dnrs")}
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    {t("formalities.lrsq_aernf_oblg_atrrr_dns_arprt_nn_dnr")}
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    {t("formalities.tt_cmrct_dt_tnr_grd_lvr")}
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    {t("formalities.tt_cmrct_prsnn_mrl_rspct_dspt")}
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    {t("formalities.jrnl_lvr_ivtr_mtnr_nmr_imtrclt")}
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    {t("formalities.tt_prsnn_mrl_cmrct_dt_etblr_etts_fncrs")}
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    {t("formalities.dcs_xgbls_acmplssmt_frmlts_ddnmt")}
                </Typography>
                <ul>
                    <li>
                        {t("formalities.dclrt_en_dn")}
                    </li>
                    <li>
                        {t("formalities.fctr_frnssr")}
                    </li>
                    <li>
                        {t("formalities.itt_imrtt")}
                    </li>
                    <li>
                        {t("formalities.dcs_trst")}
                    </li>
                    <li>
                        {t("formalities.dclrt_vl_dn")}
                    </li>
                    <li>
                        {t("formalities.crtfcts_sntrs_phtsntr_ts_atrs_dcs_prduits_almnts")}
                    </li>
                    <li>
                        {t("formalities.crtfct_orgnr_ldt_mrchds")}
                    </li>
                    <li>
                        {t("formalities.atrst_spcl_iprtt_crts_prdts")}
                    </li>
                </ul>
                <Typography sx={{ textAlign: 'justify' }}>
                    {t("formalities.dcs_xgbls_xprtt_st")}
                </Typography>
                <ul>
                    <li>
                        {t("formalities.dclrt_xprtt")}
                    </li>
                    <li>
                        {t(("formalities.fctr"))}
                    </li>
                    <li>
                        {t("formalities.brdr_xprtt")}
                    </li>
                    <li>
                        {t("formalities.lst_clsg")}
                    </li>
                    <li>
                        {t("formalities.lcc_xprtt")}
                    </li>
                    <li>
                        {t("formalities.crtfct_orgn")}
                    </li>
                </ul>
            </Box>
        },
    ]


    useEffect(() => {
        document.title = t("menu.conditions");
    }, [t]);

    return (
        <Layout>
            <Baner title={t("menu.conditions")} subTitle={t(formalities.page_ttl)} />
            <Box sx={{width: '100%', backgroundImage: `url(${Bg})`, backgroundSize: 'cover', backgroundPosition: 'top', backgroundRepeat: 'no-repeat'}}>
            <Container sx={{py: 4, backgroundColor: 'white'}}>
                {content !== undefined ? (
                    <div style={{backgroundColor: 'white'}} dangerouslySetInnerHTML={{__html: content[i18n.language][0].describe}} />
                ) : (
                    <CircularProgress />
                )}
            </Container>
            {/* <Container sx={{ py: 2 }}>
                <Typography sx={{textAlign: 'justify', my: 2, borderLeft: '5px solid', borderColor: "#157200", pl: 2, bgcolor: '#fff'}}>
                    {t("formalities.status_conditions")}
                </Typography>
                {formalities.map((form, i) => (
                    <Accordion key={form.title} defaultExpanded={i === 0}>
                        <AccordionSummary
                            expandIcon={<ExpandMore color="secondary" fontSize="medium" />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography sx={{ fontWeight: '600', color: '#157200' }} variant="h6">{form.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {form.content}
                        </AccordionDetails>
                    </Accordion>
                ))}

            </Container> */}
            </Box>
        </Layout>
    )
}