import React, { useState, useEffect } from "react";

import Layout from "../components/ui/layout";
import Slider from "../components/ui/home/slider";
// import Hero from "../components/ui/home/hero";
import Plateforme from "../components/ui/home/plateforme";
import Theques from "../components/ui/home/theques";

import Slide1 from "../images/slide1.png";
import Slide2 from "../images/slide2.png";
import Slide3 from "../images/slide3.png";

import BgT from "../images/t_bgwo.png";
import BgC from "../images/c_bgwo.png";
import Type from "../components/ui/home/type";
// import Top from "../components/ui/home/top";

import TbgCommerce from "../images/tbg_commerce.jpg";
import TbgTransport from "../images/tbg_transport.jpg";

import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import { useTranslation } from "react-i18next";
// import { CircularProgress } from "@mui/material";

import CapVert from "../images/Cap_Vert.png";
import Burkina from "../images/Burkina Faso.png";
import Niger from "../images/Niger.png";
import Mauritania from "../images/Mauritania.png";
import Mali from "../images/Mali.png";
import Senegal from "../images/Senegal.png";
import Gambia from "../images/Gambi.png";
import Guinea from "../images/Guinee.png";
import Tchad from "../images/Tchad.png";
import Benin from "../images/Benin.png";
import Ci from "../images/ci.png";
import Togo from "../images/tg.png";
import GuineaBissau from "../images/guinea-bissau.png";
// import Opportunities from "../components/ui/home/market";
import Market from "../components/ui/home/market";
import axios from "../api/without_user_token";

export default function Home() {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [topMessage, setTopMessage] = useState([]);
  const [opportunities, setOpportunities] = useState([])
  const { t, i18n } = useTranslation("global");

  useEffect(()=>{
    document.title = "La plateforme de diffusion de textes communautaires";
  },[])

  useEffect(()=>{
    const getOpportunities = () => {
      axios
        .get("get_opportunity_by_lang", {
          params: { lang: i18n.language },
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.status === 200) {
            if (i18n.language === "fr") {
              setOpportunities(response.data);
            }
          }
        })
        .catch((err) => {
          console.log("Error occur in getOpportunities: " + err);
        });
    };

    getOpportunities()

  },[i18n.language])

  useEffect(() => {

    const getDataTextHome = () => {
      axios
        .get("get_most_read_data_text", {
          params: { lang: i18n.language },
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.status === 200) {
            setData(response.data);
            setLoadData(true);

            if (response.data.length !== 0) {
              setTopMessage([
                {
                  name: t("About trade"),
                  icon: <AddBusinessIcon />,
                  img: TbgCommerce,
                  messages: response.data[0].data.map((commerce) => ({
                    actor: commerce.sub_domain_name,
                    texte: commerce.text,
                    link: commerce.link
                  })),
                },
                {
                  name: t("About transportation"),
                  icon: <LocalShippingIcon />,
                  img: TbgTransport,
                  messages: response.data[1].data.map((transport) => ({
                    actor: transport.sub_domain_name,
                    texte: transport.text,
                    link: transport.link
                  })),
                },
              ]);
            }
          }
        })
        .catch((err) => {
          console.log("Error occur in getDataTextHome: " + err);
        });
    };

    getDataTextHome();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);


  const [slindex, setSlindex] = useState(0);

  const slides = [
    {
      title: "slide1",
      description: "Description",
      img: Slide1,
    },
    {
      title:
        "slide2",
      description: "Description2",
      img: Slide2,
    },
    {
      title: "slide3",
      description: "Description3",
      img: Slide3,
    },
  ];

  const heroItems = [
    { title: t("hero.Transport"), img: BgT, link: "/messages/transport" },
    { title: t("hero.Trade"), img: BgC, link: "/messages/commerce" },
  ];

  const types = [
    {
      title: t("types.Key messages that everyone can understand"),
      link: "/",
      color: "#000",
    },
    { title: t("types.Targeting key players"), link: "/", color: "#000166" },
    { title: t("types.Action and recourse methods"), link: "/", color: "#FDE50D" },
  ];

  let commerce_msg = [];
  let transport_msg = [];
  if (data.length !== 0) {
    for (const commerce of data[0].data) {
      commerce_msg.push({
        actor: commerce.sub_domain_name,
        texte: commerce.text,
      });
    }

    for (const transport of data[1].data) {
      transport_msg.push({
        actor: transport.sub_domain_name,
        texte: transport.text,
      });
    }
  }


  const countries = [
    { countryCode: "bf", name: "Burkina Faso", tollFree: 3488, flag: Burkina },
    { countryCode: "bj", name: "Benin", tollFree: null, flag: Benin },
    { countryCode: "ci", name: "Ivory Cost", tollFree: null, flag: Ci },
    { countryCode: "gm", name: "Gambia", tollFree: 1616, flag: Gambia },
    {
      countryCode: "gw",
      name: "Guinea-Bissau",
      tollFree: null,
      flag: GuineaBissau,
    },
    { countryCode: "mr", name: "Mauritania", tollFree: null, flag: Mauritania },
    { countryCode: "sn", name: "Senegal", tollFree: 200800, flag: Senegal },
    { countryCode: "ml", name: "Mali", tollFree: 36065, flag: Mali },
    { countryCode: "ne", name: "Niger", tollFree: null, flag: Niger },
    { countryCode: "cv", name: "Cape Verde", tollFree: null, flag: CapVert },
    { countryCode: "td", name: "Tchad", tollFree: 1324, flag: Tchad },
    { countryCode: "tg", name: "Togo", tollFree: null, flag: Togo },
    { countryCode: "gn", name: "Guinea", tollFree: null, flag: Guinea },
  ];

  return (
    <Layout>
      <Slider slides={slides} slindex={slindex} setSlindex={setSlindex} />
      {/* <Hero heroItems={heroItems} /> */}
      <Type types={types} />
      <Market />
      {/* {topMessage.length === 0 ? (
        <div style={{ padding: "2rem 10rem" }}>
          {" "}
          <CircularProgress />{" "}
        </div>
      ) : (
        <Top messages={topMessage} />
      )} */}
      <Plateforme countries={countries} />
      <Theques />
      <hr/>
    </Layout>
  );
}