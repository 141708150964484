import { LockReset, Logout, Settings } from "@mui/icons-material"
import { ListItemIcon, Menu, MenuItem } from "@mui/material"
import { useValue } from "../../../context/ContextProvider"
import Profile from "./profile"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"


const UserMenu = ({anchorUserMenu, setAnchorUserMenu}) => {
    const { state: {currentUser}, dispatch} = useValue()
    const {t} = useTranslation('global')
    const handleCloseUserMenu = () =>{
        setAnchorUserMenu(null)
    }

    const navigate = useNavigate()

    

    const logOut = ()=>{
        dispatch({type: 'UPDATE_USER', payload:null})
        navigate('/admin/login')
    }

  return (
    <>
    <Menu
        anchorEl={anchorUserMenu}
        open={Boolean(anchorUserMenu)}
        onClose={handleCloseUserMenu}
        onClick={handleCloseUserMenu}
    >
        <MenuItem onClick={()=>dispatch({type: "UPDATE_PROFILE", payload: {open: true, file: null, photoURL: currentUser.photoURL}})}>
            <ListItemIcon>
                <Settings />
            </ListItemIcon>
            {t('admin.user_menu_profile')}
        </MenuItem>
        <MenuItem onClick={logOut}>
            <ListItemIcon>
                <Logout />
            </ListItemIcon>
            {t('admin.user_menu_logout')}
        </MenuItem>
        <MenuItem onClick={() =>dispatch({type: "OPEN_PWD"})}>
            <ListItemIcon>
                <LockReset />
            </ListItemIcon>
            {t('admin.user_menu_change_pwd')}
        </MenuItem>
        
    </Menu>
    <Profile />
    </>
  )
}

export default UserMenu