import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useValue } from "../../context/ContextProvider";

import Header from "./admin/header";
import Main from "./admin/main";
import Nav from "./admin/nav";


function AdminLayout(props) {
  const {
    state: { currentUser },
  } = useValue();
  const [openNav, setOpenNav] = useState(false);



  return (
    <>
      <Header onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          bgcolor: '#f7fdf6',
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        {currentUser && <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />}

        <Main>{props.children}</Main>

      </Box>

    </>
  );
}

AdminLayout.propTypes = {
  windows: PropTypes.func,
};

export default AdminLayout;
