import React, { useEffect, useState } from "react";
import { IconButton, alpha } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import CircleIcon from '@mui/icons-material/Circle';
import axios from "../../../api/without_user_token";


const  Slider = ({ slides, slindex, setSlindex }) => {

    const {i18n} = useTranslation("global");
    const [images, setImages] = useState([])

    useEffect(()=>{
        const timer = setTimeout(()=>{
            if(slindex===2){
                setSlindex(0)
            }else{
                setSlindex(slindex + 1)
            }
            return ()=> clearTimeout(timer)
        }, 5000)
    }, [slindex, setSlindex])

    useEffect(()=>{
        const getImages = async () => {
            try {
              const response = await axios.get("get_carousels_by_lang", {
                params: { lang: i18n.language },
                headers: { "Content-Type": "application/json" },
              });
              if (response.status === 200) {
                setImages(response.data);
              }
            } catch (error) {
              console.log("Error occur in getVideos: " + error);
            }
          };

          getImages()
    },[i18n.language])

    return(
        <Grid container 
        sx={{backgroundImage: `url(${images[slindex]?.image_path})`, backgroundSize: 'cover', backgroundPosition: 'center'}} direction='column'>
            <Grid container sx={{px: {xs: '1rem',sm: '2rem', md: '5rem', lg: '10rem'}, pt: '13.5rem', pb: '0', backgroundColor: alpha("#000",  0.4)}}>
                <Grid item container direction='column'>
                    <Grid item sx={{pb: '13.5rem'}}>
                        <Typography sx={{color: '#fff', fontWeight: '700', fontSize: {xs: '1.5rem', sm: '2.5rem'}}}>{images[slindex]?.describe}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row' alignContent='center' alignItems='center' justifyContent='center'>
                    <Grid item>
                        <IconButton onClick={() => setSlindex(0)}>
                            <CircleIcon fontSize="small" sx={{color: slindex === 0 ? "#fff" : alpha('#000', 0.7)}} color="#fff" />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setSlindex(1)}>
                            <CircleIcon fontSize="small" sx={{color: slindex === 1 ? "#fff" : alpha('#000', 0.7)}} color="#fff" />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setSlindex(2)}>
                            <CircleIcon fontSize="small" sx={{color: slindex === 2 ? "#fff" : alpha('#000', 0.7)}} color="#fff" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default Slider