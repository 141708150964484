import { Close, Send } from '@mui/icons-material'
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useValue } from '../../../context/ContextProvider'
import axios from 'axios'

const Profile = () => {
    const { state: { profile, currentUser }, dispatch } = useValue()
    const [progress, setProgress] = useState(-1)
    const [photo, setPhoto] = useState(null)


    const usernameRef = useRef()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const emailRef = useRef()

    const handleClose = () => {
        dispatch({ type: 'UPDATE_PROFILE', payload: { ...profile, open: false } });
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({ type: "START_LOADING" })

        const firstName = firstNameRef.current.value
        const lastName = lastNameRef.current.value
        const username = usernameRef.current.value
        const email = emailRef.current.value

        const fd = new FormData()
        fd.append('photo_profil', photo)
        fd.append('user_id', currentUser.userId)
        fd.append('first_name', firstName)
        fd.append('last_name', lastName)
        fd.append('username', username)
        fd.append('email', email)

        axios.post('update_user', fd, {
            onUploadProgress: (progressEvent) => { setProgress(progressEvent.progress * 100) },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${currentUser.token}`
            }
        }).then(res => {
            console.log(res)
            handleClose()
            window.location.reload()
        }).catch(err => {
            console.error(err)
        })

        dispatch({ type: 'END_LOADING' });
    }

    const handlePhotoChange = (e) => {
        const file = e.target.files[0]
        if (file) {
            const photoURL = URL.createObjectURL(file)
            dispatch({ type: 'UPDATE_PROFILE', payload: { ...profile, file, photoURL: photoURL } })
        }
        setPhoto(file)
    }


    return (
        <Dialog
            open={profile.open}
            onClose={handleClose}
        >
            <DialogTitle>
                Modification de profile
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.5rem',
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <form id='editForm' onSubmit={handleSubmit}>
                <DialogContent dividers>
                    <Box display="flex" justifyContent="center" sx={{ width: "100%" }} variant="div">
                        <label htmlFor='photo'>
                            <input
                                accept='image/*'
                                id="photo"
                                type='file'
                                style={{ display: 'none' }}
                                onChange={handlePhotoChange}
                            />
                            <Avatar src={profile.photoURL} sx={{ height: 75, width: 75, cursor: 'pointer' }} />
                        </label>
                        {progress !== -1 && <CircularProgress variant='determinate' value={progress} />}
                    </Box>
                    <TextField
                        autoFocus
                        margin='normal'
                        variant='standard'
                        id='first_name'
                        type='text'
                        fullWidth
                        inputRef={firstNameRef}
                        value={currentUser.firstName}
                        inputProps={{ minLength: 2 }}
                        placeholder='Prénom'
                        required
                    />
                    <TextField
                        margin='normal'
                        variant='standard'
                        id='last_name'
                        type='text'
                        fullWidth
                        inputRef={lastNameRef}
                        inputProps={{ minLength: 2 }}
                        value={currentUser.lastName}
                        placeholder='Nom'
                        required
                    />
                    <TextField
                        margin='normal'
                        variant='standard'
                        id='username'
                        type='text'
                        fullWidth
                        inputRef={usernameRef}
                        value={currentUser.username}
                        inputProps={{ minLength: 2 }}
                        placeholder="Nom d'utilisateur"
                        required
                    />
                    <TextField
                        margin='normal'
                        variant='standard'
                        id='email'
                        type='email'
                        fullWidth
                        inputRef={emailRef}
                        inputProps={{ minLength: 2 }}
                        value={currentUser.email}
                        placeholder='Email'
                        required
                    />
                </DialogContent>
                <DialogActions sx={{ px: '19px' }}>
                    <Button sx={[{ bgcolor: '#157200' }, { "&:hover": { bgcolor: '#2c711c' } }]} type='submit' variant='contained' endIcon={<Send />}>
                        Enregistrer
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default Profile