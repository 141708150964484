import axios from "../../api/axios"

const fetchData = async ({url}, dispatch) => {
    try {
        const response = await axios.get(url, {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer mHf1toDHlu0gcjHmCgAr2k6jp7sWUc0DzvB79gjX9aLLwMpqrX3YS3LQJUU3TQeWk9lTb8D003vE9T8f1TelRuggRUliKUXGDQLiWAZMFMZYVAEdMI4tEwGp"
            },
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        dispatch({
            type: 'UPDATE_ALERT',
            payload: {
                open: true,
                severity: 'error',
                message: err.message
            }
        });
        console.log('Error occur in getDataText: ' + err);
        return null
    }

    return null;
}

export default fetchData