import { useEffect } from 'react'
import { useValue } from '../context/ContextProvider'
import { getUsers } from '../actions/user'


const useCheckActive = () => {
  const {state: {users}, dispatch} = useValue()

    useEffect(() => {
        const fetchActors = async () => {
            if(users.length === 0){
                await getUsers(dispatch);
            }
            const currentUser = JSON.parse(localStorage.getItem('currentUser'))
            if(currentUser !== null){
                    const connectedUser = users.filter(user => user.user_id === currentUser.userId)
                    if(connectedUser.length !== 0){
                        if(connectedUser[0].is_active === false){
                            dispatch({
                                type: 'UPDATE_ALERT',
                                payload: {
                                  open: true,
                                  severity: 'error',
                                  message: "compte desactivé. Veuillez contacter l'administrateur"
                                },
                              })
                            dispatch({type: 'UPDATE_USER', payload:null})
                        }
                    }
            }
        }
        fetchActors()
    }, [dispatch, users])
}

export default useCheckActive