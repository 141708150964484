import React, { useEffect, useMemo, useRef, useState } from "react";
import AdminLayout from "../../components/ui/admin_layout";
import { Typography, Box, IconButton, Grid, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Fab, CircularProgress, MenuItem, Tabs, Tab } from "@mui/material";
import {DataGrid} from '@mui/x-data-grid'
import { Add, Check, Close, Delete, Edit } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { useValue } from "../../context/ContextProvider";
import { getActors, getDomains } from "../../actions/actor";
import axios from "axios";
import { useTranslation } from "react-i18next";
import useCheckActive from "../../hooks/useCheckActive";
import useCheckConnexionDuration from "../../hooks/useCheckConnexionDuration";

const Categories = () => {
    useCheckConnexionDuration()
    useCheckActive()
    const { state: {actors, domains, currentUser}, dispatch }   = useValue()
    const [selectedLang, setSelectedLang]                       = useState(1)
    const [activeDomain, setActiveDomain]                       = useState(0)
    const [actorNameEdit, setActorNameEdit]                     = useState()
    const [file, setFile]               = useState(null);
    const [pageSize, setPageSize]       = useState(10);
    const [open, setOpen]               = useState(false);
    const [rowId, setRowId]             = useState(null);
    const [rowLoading, setRowLoqding]   = useState(false);
    const [rowSuccess, setRowSuccess]   = useState(false);
    const [openEdit, setOpenEdit]       = useState(false);
    const [actorUuid, setActorUuid]     = useState(null);
    const [actorLang, setActorLang]     = useState();
    const actorNameRef                  = useRef();
    const nameRef                       = useRef();
    const {t}                     = useTranslation("global");

    useEffect(() => {
        const fetchActors = async () => {
            if (actors.length === 0) {
                await getActors(dispatch);
            }
        };
        const fetchDomains = async() =>{
            if(domains.length === 0) {
                await getDomains(dispatch);
            }
        }
    
        fetchActors()
        fetchDomains()
    }, [actors, dispatch, domains]);

    const handleEditSubmit = async (e) =>{
        e.preventDefault()
        dispatch({type: "START_LOADING"})

        const data ={
            lang        : actorLang,
            uuid        : actorUuid,
            name        : actorNameEdit,
            image_path  : file
        }

        const config = {
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${currentUser.token}`
            }
        };
        
        axios.post('update_sub_domain', data, config).then(response => {
            if(response.status === 200){
                dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'success',
                        message: "Acteur mise à jour avec succès"
                    }
                })
                setActorNameEdit('')
                setOpenEdit(false)
                window.location.reload()
            }
        }).catch(err => {
            dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: err.message
                }
            })
        })

        dispatch({type: "END_LOADING"})
    }

    const handleDelete = async (params) => {
        dispatch({type: 'START_LOADING'})

        const data = { uuid: params.row.uuid }
        
        const config = {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${currentUser.token}`
            }
        };

        axios.post('delete_sub_domain', data, config).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'success',
                        message: "Acteur supprimé avec succès"
                    }
                })
                window.location.reload()
            }
        }).catch((err) => {
            dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: err.message
                }
            })
        })
        dispatch({type: 'END_LOADING'})
    }
    
    const columns = useMemo(() => [
        {field: 'uuid', headerName: "Uuid", width: 400, editable: false},
        {field: 'name', headerName: t("admin.column_name"), width: 450, editable: false},
        {field: "actions", headerName: "Actions", width: 200,
        renderCell: params => (
            <Box sx={{m:1, position: 'relative'}}>
                {rowSuccess ? (
                    <Fab sx={{
                        width: 40,
                        height: 40,
                        bgcolor: 'green',
                        "&:hover": {bgcolor: '#157200'}
                    }}>
                        <Check />
                    </Fab>
                ) : (
                    <Fab sx={{ width: 40, height: 40, }} onClick={() => handleEditOpen(params)} >
                        <Edit />
                    </Fab>
                )}
                {rowLoading && (
                    <CircularProgress size={52} sx={{
                        color: green[500],
                        position: 'absolute',
                        top: -6,
                        left: -6,
                        zIndex:1
                    }} />
                )}
                <Fab onClick={() => handleDelete(params)} sx={{
                        width: 40,
                        height: 40,
                        mx: '1rem',
                        "&:hover": {bgcolor: '#157200', color: 'white'}
                    }}>
                    <Delete />
                </Fab>
            </Box>
            )
        }
    ], [rowId, rowLoading, rowSuccess])

    const langToFilter      = selectedLang === 0 ? 'en' : 'fr'
    const domainsFiltered   = domains.length !==0 ? domains.filter(domain => domain.lang === langToFilter) : []
    
    const rows = domains.length !==0 ?  domainsFiltered[activeDomain]?.sub_domain_name?.map(actor =>({
        lang: actor.lang,
        name: actor.name,
        uuid: actor.uuid,
        id: actor.id
    })) : []
    
    const handlechange = (event, newValue) => {
        setActiveDomain(newValue)
    }

    const handleEditOpen = (params) =>{
        setOpenEdit(true)
        setActorLang(params.row.lang)
        setActorNameEdit(params.row.name)
        setActorUuid(params.row.uuid)
    }

    const handleAddSubmit = async (e) =>{
        e.preventDefault()
        dispatch({type: 'START_LOADING'})
        let data = {
            lang        : selectedLang === 1 ? 'fr' : 'en',
            user_id     : currentUser.userId,
            name        : nameRef.current.value,
            domain_id   : domainsFiltered[activeDomain]?.id,
            image_path  : file
        }
        
        const config = {
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${currentUser.token}`
            }
        };

        axios.post('add_sub_domain', data, config).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'success',
                        message: "Acteur ajouté avec succès"
                    }
                })
                dispatch({type: 'END_LOADING'})
                setOpen(false)
                window.location.reload()
            }
        }).catch((err) => {
            dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: err.message
                }
            })
            dispatch({type: 'END_LOADING'})
         })

        dispatch({type: 'END_LOADING'})
    }

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    return(
        <AdminLayout>
            <Box sx={{ height: "100%" }}>
                <Typography variant="h3" component='h3' sx={{textAlign: 'center'}}>
                    { t("admin.title_manage_actor") }
                </Typography>
                <Box sx={{display: 'flex', direction: 'row', justifyContent: 'flex-end'}} >
                    <TextField
                        id="standard-select-currency"
                        select
                        label={ t("admin.choose_language") }
                        sx={{width: '15rem'}}
                        defaultValue={selectedLang}
                        onChange={(e)=> setSelectedLang(e.target.value)}
                        variant="standard"
                    >
                        {actors.map((lang, i) => (
                            <MenuItem key={i} value={i}>
                                {i === 0 ? "English" : "Français"}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                {domainsFiltered.length === 0 ? (<CircularProgress />) : (
                    <Tabs 
                    sx={{minWidth: '0'}} 
                    indicatorColor="#157200" 
                    allowScrollButtonsMobile 
                    value={activeDomain} 
                    onChange={handlechange} 
                    variant="fullWidth" 
                    scrollButtons='auto' 
                    aria-label="scrollable auto tabs">
                    {domainsFiltered.map((domain, i) => (
                        <Tab key={i} sx={[{'&.Mui-selected': {
                            color: activeDomain === i ? 'primary' : '#000',
                            fontWeight: '600',
                            borderBottom: "3px solid",
                            borderBottomColor: "#157200",
                            bgcolor: activeDomain === i ? 'primary' : '#fff',

                          }},{fontWeight: '500', bgcolor: activeDomain === i ? 'primary' : '#fff', fontSize: '1rem', textTransform: 'none', textAlign: 'left', mx: '0.2rem', color: activeDomain === i ? '#fff' : '#000', py: '0.2rem'}]} label={domain.domain_name} />
                        ))}
                </Tabs>
                )}
                <Grid container direction='row' justifyContent='flex-end'>
                    <Grid item>
                        <Button onClick={() => setOpen(true)} sx={{textTransform: 'none', color: '#157200', fontWeight: '600'}} variant="text" startIcon={<Add />}>
                            { t("admin.btn_add_actor") }
                        </Button>
                        <Dialog open={open} onClose={() => setOpen(false)}>
                            <DialogTitle>
                                <Typography>
                                    { t("admin.btn_add_actor") }
                                </Typography>
                                <IconButton 
                                    sx={{
                                        position: 'absolute',
                                        top: '0.5rem',
                                        right: '0.5rem',
                                        color: (theme) => theme.palette.grey[500]
                                    }}
                                    onClick={() =>setOpen(false)}
                                >
                                    <Close />
                                </IconButton>
                            </DialogTitle>
                            <form onSubmit={handleAddSubmit}>
                                <DialogContent sx={{py: '2rem'}} dividers>
                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        label={ t("admin.choose_language") }
                                        sx={{width: '15rem'}}
                                        defaultValue={selectedLang}
                                        onChange={(e)=> setSelectedLang(e.target.value)}
                                        variant="standard"
                                    >
                                        {actors.map((lang, i) => (
                                            <MenuItem key={i} value={i}>
                                                {i === 0 ? "English" : "Français"}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        autoFocus
                                        margin='normal'
                                        variant='standard'
                                        id='fr'
                                        type='text'
                                        fullWidth
                                        placeholder="Nom de l'acteur"
                                        inputRef={nameRef}
                                        inputProps={{minLength:2}}
                                        required
                                    />

                                    <TextField
                                        autoFocus margin='normal' onChange={event => handleFileChange(event)} 
                                        inputRef={actorNameRef} variant='standard' type='file' fullWidth
                                        placeholder="Icon"
                                    />
                                </DialogContent>
                                <DialogActions sx={{px: "19px"}}>
                                    <Button type="submit" variant="contained" sx={[{bgcolor: '#157200'},{"&:hover": {bgcolor: 'green'}}]} startIcon={<Add />}>
                                        { t("admin.btn_add") }
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>
                        <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
                            <DialogTitle>
                                <Typography> { t("admin.title_edit_actor") } </Typography>

                                <IconButton 
                                    sx={{
                                        position: 'absolute',
                                        top: '0.5rem',
                                        right: '0.5rem',
                                        color: (theme) => theme.palette.grey[500]
                                    }}
                                    onClick={() => setOpenEdit(false)}
                                >
                                    <Close />
                                </IconButton>
                            </DialogTitle>

                            <form onSubmit={handleEditSubmit}>
                                <DialogContent sx={{py: '2rem'}} dividers>
                                    <TextField
                                        autoFocus margin='normal' value={actorNameEdit} onChange={(event) => setActorNameEdit(event.target.value)} inputRef={actorNameRef} variant='standard' type='text' fullWidth
                                        placeholder="Nom"
                                    />

                                    <TextField
                                        autoFocus margin='normal' onChange={event => handleFileChange(event)} 
                                        inputRef={actorNameRef} variant='standard' type='file' fullWidth
                                        placeholder="Icon"
                                    />
                                </DialogContent>

                                <DialogActions sx={{px: "19px"}}>
                                    <Button type="submit" variant="contained" sx={[{bgcolor: '#157200'},{"&:hover": {bgcolor: 'green'}}]} startIcon={<Add />}>
                                        { t("admin.btn_edit") }
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>
                    </Grid>
                </Grid>
                {rows.length === 0 ? (<CircularProgress />) : (
                    <DataGrid 
                        columns={columns}
                        rows={rows}
                        getRowId={(row) =>row.uuid}
                        autoHeight
                        rowsPerPageOptions={[5, 10, 25]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onCellEditCommit={(params)=>setRowId(params.id)}
                    />
                )}
            </Box>
        </AdminLayout>
    )
}
export default Categories