import { Container, Grid, Typography } from "@mui/material";
import React from "react";

const Baner = ({ title, subTitle }) => {

    return (
        <Grid container sx={{ backgroundColor: '#157200', py: 2 }} justifyContent='center' direction='column'>
            <Container>
                <Grid item>
                    <Typography sx={{ fontSize: { xs: '1.575rem', sm: '3.125rem', py: '1.5rem' }, fontWeight: '700', color: '#fff' }}>{title}</Typography>
                </Grid>
                <Grid item sx={{ mb: '-1rem' }}>
                    <Typography sx={{ fontSize: '1.375rem', color: '#fff' }}>{subTitle}</Typography>
                </Grid>
            </Container>
        </Grid>
    )
}

export default Baner