import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Chiffres ({ title, total, icon, color="primary", sx, ...other}) {

    return (
        <Card
          component={Stack}
          spacing={3}
          direction="row" 
          sx={{
            px: 1,
            py: 3,
            borderRadius: 1,
            ...sx,
          }}
          {...other}
        >
          {icon && <Box sx={{ width: 64, height: 64 }}>{icon}</Box>}
    
          <Stack spacing={0.5}>
            <Typography variant="h4">{total}</Typography>
    
            <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
              {title}
            </Typography>
          </Stack>
        </Card>
      );
}