import {v4 as uuidv4} from "uuid"
import fetchData from "./utils/fetchData"
import { patchData } from "./utils/patchData";
import axios from "../api/axios";

export const getUsers = async (dispatch) => {
    dispatch({ type: 'START_LOADING' });

    try {
        const users = await fetchData({url: "get_users"}, dispatch);

        if (users) {
            dispatch({type: 'UPDATE_USERS', payload: users});
        }
    } finally {
    }
    dispatch({ type: 'END_LOADING' });
}

export const updateUser = async (updatedFields, token, dispatch) => {

    return await patchData({url: "update_user", updatedFields, token}, dispatch)
}

export const updateWithProfile = async(currentUser, updatedFields, setProgress, dispatch) =>{
    dispatch({type: "START_LOADING"})

    const {firtName, lastName, username, email, file} = updatedFields
    let data = {
        user_id: currentUser.userId,
        first_mame: firtName, 
        last_name: lastName, 
        username: username, 
        email, 
        photo_profil: file
    }
    
    axios.post('update_user', data, {
        onUploadProgress: (progressEvent) => {setProgress(progressEvent.progress*100)},
        headers: { 'Content-Type': 'multipart/form-data',
                   'Authorization': `Token ${currentUser.token}`
        }
    }).then(res =>{
        console.log(res)
    }).catch(err => {
        console.error(err)
    })

    dispatch({ type: 'END_LOADING' });
}