

const reducer = (state, action) =>{
    switch(action.type) {
        case 'OPEN_LOGIN':
            return {...state, openLogin: true};
        case 'CLOSE_LOGIN':
                return {...state, openLogin: false};
        
        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};

        case 'UPDATE_NOTIF':
            return {...state, notif_count: action.payload}

        case 'UPDATE_ALERT':
            return {...state, alert: action.payload};
        case 'UPDATE_ACTORS':
            return {...state, actors: action.payload}
        case 'UPDATE_USERS':
                return {...state, users: action.payload}
        case 'UPDATE_DOMAINS':
                    return {...state, domains: action.payload}
        case 'UPDATE_PROFILE':
                    return {...state, profile: action.payload}
        case 'OPEN_PWD':
                return {...state, pwd: true}
        case 'CLOSE_PWD':
            return {...state, pwd: false}
        case 'UPDATE_USER':
            localStorage.setItem('currentUser', JSON.stringify(action.payload))
            return {...state, currentUser: action.payload};

        default:
            throw new Error('Aucune action correspondante !')
    }
}
export default reducer