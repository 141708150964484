import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Tooltip, Typography } from "@mui/material";

import LogoCilss from "../../images/logocilss.png"
import CapVert from "../../images/Cap_Vert.png"
import Burkina from "../../images/Burkina Faso.png"
import Niger from "../../images/Niger.png"
import Mauritania from "../../images/Mauritania.png"
import Mali from "../../images/Mali.png"
import Senegal from "../../images/Senegal.png"
import Gambia from "../../images/Gambi.png"
import Guinea from "../../images/Guinee.png"
import Tchad from "../../images/Tchad.png"
import CilssCard from "../../images/cilss_card_1.png"
import Benin from "../../images/Benin.png";
import Ci from "../../images/ci.png"
import Togo from "../../images/tg.png"
import GuineaBissau from "../../images/guinea-bissau.png"

import LogoLigth from "../../images/logodef.png"
import { Link } from "react-router-dom";

export default function Footer() {

    const { t } = useTranslation("global");

    const countries = [
        { nom: t("footer.Cap Vert"), src: CapVert },
        { nom: t("footer.Burkina Faso"), src: Burkina },
        { nom: t("footer.Niger"), src: Niger },
        { nom: t("footer.Mauritanie"), src: Mauritania },
        { nom: t("footer.Mali"), src: Mali },
        { nom: t("footer.Sénégal"), src: Senegal },
        { nom: t("footer.Gambie"), src: Gambia },
        { nom: t("footer.Guinée"), src: Guinea },
        { nom: t("footer.Tchad"), src: Tchad },
        { nom: t("footer.Benin"), src: Benin },
        { nom: t("footer.Côte d'Ivoire"), src: Ci },
        { nom: t("footer.Togo"), src: Togo },
        { nom: t("footer.Guinée Bissau"), src: GuineaBissau },
    ]


    const organisations = [
        { name: 'CILSS', site: "https://www.cilss.int/" },
        { name: 'CEDEAO', site: "https://www.ecowas.int/" },
        { name: 'UEMOA', site: "https://www.uemoa.int/" },
        { name: 'OHADA', site: "https://www.ohada.com/" }
    ]

    const links = [
        // { name: t("footer.Messages about trade"), link: "/messages/commerce" },
        // { name: t("footer.Messages about transport"), link: "/messages/transport" },
        // { name: t("footer.Magazines"), link: "/#" },
        { name: t("footer.admin"), link: "/admin/dashboard" },
        { name: t('header.btn_config'), link: "/qrcode" }
    ]


    return (
        <Grid container direction="column">
            <Grid container sx={{ px: { xs: '1rem', sm: '2rem', md: '5rem', lg: '10rem' }, backgroundColor: '#157200', py: '1rem' }} item direction='column'>
                <Grid item justifyContent='space-between' alignItems={{ sm: 'flex-start', xs: 'center' }} alignContent='flex-start' direction={{ xs: 'column', sm: 'row' }} container sx={{ pt: '3rem' }}>
                    <Grid item container xs={2} alignItems='center' direction='column'>
                        <Grid item sx={{ pb: '1rem' }}>
                            <img style={{ width: "150px"  }} src={LogoCilss} alt="Logo du CILSS" />
                        </Grid>
                        <Grid item gap={2} container justifyContent='space-between' alignItems='center' alignContent='center'>
                            {countries.map((country, i) => (
                                <Tooltip key={i} title={country.nom}>
                                    <img src={country.src} style={{ width: '2.2rem', height: '1.5rem', py: '1rem' }} alt={country.nom} />
                                </Tooltip>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item container xs={2} alignItems='center' direction='column'>
                        <Grid item sx={{ pb: '1rem' }}>
                            <img style={{ width: "120px"}} src={LogoLigth} alt="Logo du CILSS" />
                        </Grid>
                        <Grid item>
                            <Typography sx={{ textAlign: 'center', color: '#fff' }}>
                                {t('footer.The platform for distributing Community texts accessible to all!')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Typography sx={{ color: '#fff', fontWeight: '700' }}>
                            {t('footer.Organizations')}
                        </Typography>
                        {organisations.map(organisation => (
                            <Button variant="text" key={organisation.site} sx={{ display: 'block', color: '#fff' }} href={organisation.site}>{organisation.name}</Button>
                        ))}
                    </Grid>
                    <Grid item >
                        <Typography sx={{ color: '#fff', fontWeight: '700' }}>
                            {t('footer.Useful links')}
                        </Typography>
                        {links.map(link => (
                            <Button variant="text" href={link.link} key={link.link} sx={{ display: 'block', color: '#fff', textTransform: 'none' }}>{link.name}</Button>
                        ))}
                    </Grid>
                    <Grid item >
                        <img style={{ width: '12rem' }} src={CilssCard} alt="Carte du cliss" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ backgroundColor: '#000', py: '1rem', px: { xs: '1rem', sm: '2rem', md: '10rem' } }}>
                <Grid item>
                    <Typography sx={{ color: '#fff', textAlign: { xs: 'center', sm: 'left', md: 'left', lg: 'left' } }}>
                        {t('footer.CILSS © All rights reserved')}
                    </Typography>
                </Grid>
                <Grid item>
                        <Link to='/policy'>
                    <Typography sx={{ color: '#fff', textAlign: { xs: 'center', sm: 'left', md: 'left', lg: 'left' }, textTransform: 'none', textDecoration: 'none' }}>
                            {t('footer.Privacy policy')}
                    </Typography>
                        </Link>
                </Grid>
            </Grid>
        </Grid>
    )
}