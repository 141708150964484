import React from 'react';
import { Button, Box, Container, Grid, Typography } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import { useTranslation } from "react-i18next";

import MarketBg from "../../../images/marketbg.gif"
import Mouton from "../../../images/mouton.jpg"
import Marketimg from "../../../images/market.png";


const Market = () => {
  const { t } = useTranslation("global");

  

  return (

    <Container sx={{ py: 6 }}>
      <Box variant="div" display="flex" flexDirection="column">
        <Grid container spacing={1} sx={{ my: 1 }} justifyContent='space-between'>
          <Grid item xs={12} sm={12} md={6} sx={{pb: 10, mb: 3}}>
              <img style={{position: "relative", width: "100%"}} src={Marketimg} alt='Marché de séréal' />
            {/* <Box variant="div" display="flex" flexDirection="row" sx={{width: "100%", height: "100%", position: "relative"}} justifyContent="flex-end">
              <img style={{position: "absolute", width: "60%", bottom: -100, right: -10, border: " 8px solid white", zIndex: 2}} src={Mouton} alt='Mouton' />
            </Box> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{pt: 14, pb: 6}} container justifyContent="space-around" direction="column">
            <Grid item>
              <Typography sx={{fontWeight: '700', fontSize: '1.875rem'}} variant='h5'>{t("home.title")}</Typography>
            </Grid>
            <Grid item sx={{py: 3, pl: 1}}>
              <Typography variant='body1'>
                {t("home.desc")}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant='contained' endIcon={<ArrowRightAlt />} sx={{textTransform: 'none'}} href='https://www.plateforme.sim2g.net/'>
                {t("home.btn")}
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </Box>
    </Container>

  );
};

export default Market;
