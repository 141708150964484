import React, { useEffect, useState } from 'react';
import axios from '../api/fromcilss';
import Layout from '../components/ui/layout';
import Baner from '../components/ui/baner';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import { Box, Button, Card, Checkbox, Chip, Container, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery'
import { Check, CheckBoxOutlineBlank, Close } from '@mui/icons-material';
import { alpha, useTheme } from '@mui/material/styles';

import Empty from '../images/empty.jpg';
import { useTranslation } from 'react-i18next';
import { green } from '@mui/material/colors';

export default function Market() {
  const [offres, setOffres] = useState([]);
  const [acteurs, setActeurs] = useState([])
  const [checked, setChecked] = useState([true, true, true]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(20);
  const [units, setUnits] = useState([])
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState(0)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('global')

  useEffect(() => {
    document.title = t('market.page_ttl');
  }, []);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpen = (i) => {
    setOpen(true)
    setSelected(i)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getAllActors = async () => {
    try {
      const response = await axios.get('acteur/all', { headers: { "Content-Type": "application/json" } });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      console.error(err);
      throw err; 
    }
  }

  const getUnits = async () => {
    try {
      const res = await axios.get('unitmesure/all', { headers: { "Content-Type": "application/json" } })
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      console.error(err);
      throw err; 
    }
  }

  const getAllOffre = async () => {
    try {
      const [acteursData, unitsData] = await Promise.all([getAllActors(), getUnits()]);
      const response = await axios.get('offre/all', { headers: { "Content-Type": "application/json" } });
      if (response.status === 200) {
        const sortedData = response.data.sort((a, b) => {
          return new Date(b.dateval) - new Date(a.dateval);
        });
        const offresWithActors = sortedData.map(offre => ({
          ...offre,
          acteur: acteursData.find(acteur => acteur.id_acteur === offre.id_acteur),
          unit: unitsData.find(unit => unit.id === offre.idunit)
        }))
        setOffres(offresWithActors);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllOffre();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filtered = filterOffers();
    setTotalPages(Math.ceil(filtered.length / rowsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offres, checked, search, page, rowsPerPage]);

  const handleChange = (position) => {
    const updatedChecked = checked.map((item, index) => position === index ? !item : item);
    setChecked(updatedChecked);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };



  const offreType = (type) => {
    if (['VNT', 'vente', 'Vente'].includes(type)) {
      return t('market.Vente');
    } else if (['ACH', 'achat', 'Achat'].includes(type)) {
      return t('market.Achat');
    } else {
      return '';
    }
  };

  const filterOffers = () => {
    return offres.filter((offre) =>
      ((checked[0] && offreType(offre.typeoffre) === t('market.Vente')) ||
        (checked[1] && offreType(offre.typeoffre) === t('market.Achat'))) &&
      offre.libelle_offre.toLowerCase().includes(search.toLowerCase())
    ).filter(offre => checked[2] || offre.conclus === 0);
  };

  const paginatedOffers = () => {
    const filtered = filterOffers();
    return filtered.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };

  return (
    <Layout>
      <Baner title={t('market.ttl_opportunities')} subTitle={t('market.sub_ttl_opportunities')} />
      <Container sx={{ py: 0 }}>
        <Box variant="div" display="flex" flexDirection="column">
          <Card variant='elevation'>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', p: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={checked[0]} onChange={() => handleChange(0)} />}
                label={t("market.Vente")}
              />
              <FormControlLabel
                control={<Checkbox checked={checked[1]} onChange={() => handleChange(1)} />}
                label={t("market.Achat")}
              />
              <FormControlLabel
                control={<Checkbox checked={checked[2]} onChange={() => handleChange(2)} />}
                label={t("market.Conclue")}
              />
            </Box>

            <Box variant="div" display='flex' justifyContent='center' sx={{ mt: 2, mb: 2 }}>
              <TextField
                id="search-offres"
                label={t('market.find_in_offers')}
                type="search"
                variant="outlined"
                value={search}
                onChange={handleSearchChange}
                fullWidth
                sx={{ maxWidth: '500px' }}
              />
            </Box>


          </Card>
          <Grid container spacing={1} sx={{ my: 2 }} justifyContent='space-between'>
            {paginatedOffers().map((offre, i) => (
              <Grid key={offre?.id} item xs={12} sm={6} md={6} >
                <Card sx={{ height: '100%', borderBottom: "2px solid", borderColor: "green", pb: 0 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box display="flex" variant="div" flexDirection="column" justifyContent="flex-end" sx={{ width: '30%', backgroundImage: `url(${Empty})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
                        <Button onClick={() => handleOpen(i)} variant='text' sx={{
                          textTransform: 'none',
                        }}>
                          {(offre.libelle_offre.length > 20) ? (<Tooltip title={offre.libelle_offre}> <Typography variant='h6'>{offre.libelle_offre.substring(0, 20) + '...'}</Typography> </Tooltip>) : (<Typography variant='h6'>{offre.libelle_offre}</Typography>)}
                        </Button>

                        <Dialog
                          fullScreen={fullScreen}
                          maxWidth="md"
                          open={open}
                          onClose={handleClose}
                        >
                          <DialogTitle>
                            {paginatedOffers()[selected].libelle_offre}
                            <IconButton
                              sx={{
                                position: 'absolute',
                                top: '0.5rem',
                                right: '0.5rem',
                                color: (theme) => theme.palette.grey[500]
                              }}
                              onClick={handleClose}>
                              <Close />
                            </IconButton>
                          </DialogTitle>
                          <Divider />
                          <DialogContent sx={{ py: 2, minWidth: "20rem" }}>
                            <DialogContentText sx={{ minWidth: 140 }}>
                              {t('market.offer_dtl')}
                            </DialogContentText>
                            <Box sx={{ height: '10rem', backgroundImage: `url(${Empty})`, backgroundPosition: 'center', backgroundSize: 'cover' }} />
                            <Divider />
                            <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                              <Typography variant='body2'>{t('market.offer_type')}</Typography>
                              <Typography variant='body1'>{offreType(paginatedOffers()[selected].typeoffre)}</Typography>
                            </Box>
                            <Divider />
                            <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                              <Typography variant='body2'>{t('market.offer_status')}</Typography>
                              {(paginatedOffers()[selected].conclus === 1 || moment(paginatedOffers()[selected].dateval) < moment()) ? null : (
                                <Chip label={paginatedOffers()[selected].istatutd} color={paginatedOffers()[selected].istatutd === "En cours" ? "success" : "default"} />
                              )}
                              {(paginatedOffers()[selected].conclus === 0 && moment(paginatedOffers()[selected].dateval) < moment()) && (
                                <Chip label={t("market.Expired")} color="error" />
                              )}
                              {paginatedOffers()[selected].conclus === 1 && (
                                <Chip label={t("market.Conclue")} sx={{ bgcolor: 'blue', color: '#fff' }} color="primary" />
                              )}
                            </Box>
                            <Divider />
                            <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                              <Typography variant='body2'>{t('market.offer_is_ccld')}</Typography>
                              <Typography variant='body1'>{paginatedOffers()[selected].conclus === 0 ? t("market.offer_ccldd") : t("market.offer_nccldd")}</Typography>
                            </Box>
                            <Divider />
                            <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                              <Typography variant='body2'>{t('market.offer_price')}</Typography>
                              <Box display='flex'>
                                <Typography variant='body1'>{`${paginatedOffers()[selected].prixoffre} `} {paginatedOffers()[selected].codedev}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            {(paginatedOffers()[selected]?.unit?.namefr !== undefined) && (
                              <div>
                                <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                                  <Typography variant='body2'>{t('market.offer_unit')}</Typography>
                                  <Typography variant='body1'>{paginatedOffers()[selected]?.unit?.namefr}</Typography>
                                </Box>
                                <Divider />
                              </div>
                            )}

                            {paginatedOffers()[selected].qteoffre !== null && (
                              <div>
                                <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                                  <Typography variant='body2'>{t('market.offre_qty')}</Typography>
                                  <Typography variant='body1'>{paginatedOffers()[selected]?.qteoffre}</Typography>
                                </Box>
                                <Divider />
                              </div>
                            )}



                            <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                              <Typography variant='body2'>{t('market.offer_crtd')}</Typography>
                              <Typography variant='body1'>{moment(paginatedOffers()[selected].datecre).format('DD/MM/YYYY HH:MM')}</Typography>
                            </Box>
                            <Divider />
                            <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                              <Typography variant='body2'>{t('market.offer_date')}</Typography>
                              <Typography variant='body1'>{moment(paginatedOffers()[selected].dateoffre).format('DD/MM/YYYY')}</Typography>
                            </Box>
                            <Divider />
                            <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                              <Typography variant='body2'>{t('market.offer_vld_dt_lmt')}</Typography>
                              <Typography variant='body1'>{moment(paginatedOffers()[selected].dateval).format('DD/MM/YYYY')}</Typography>
                            </Box>

                            {paginatedOffers()[selected].acteur && (
                              <div style={{ paddingTop: 2 }}>
                                <DialogContentText sx={{ minWidth: 140 }}>
                                  {t('market.offer_act_infos')}
                                </DialogContentText>
                                <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                                  <Typography variant='body2'>{t('market.offer_acteur_fn')}</Typography>
                                  <Typography variant='body1'>{paginatedOffers()[selected]?.acteur?.prenom_acteur}</Typography>
                                </Box>
                                <Divider />
                                <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                                  <Typography variant='body2'>{t('market.offer_act_ln')}</Typography>
                                  <Typography variant='body1'>{paginatedOffers()[selected]?.acteur?.nomacteur}</Typography>
                                </Box>
                                <Divider />
                                <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                                  <Typography variant='body2'>{t('market.offer_act_ntnlty')}</Typography>
                                  <Typography variant='body1'>{paginatedOffers()[selected]?.acteur?.nationalite}</Typography>
                                </Box>
                                <Divider />
                                <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                                  <Typography variant='body2'>{t('market.offer_act_tel')}</Typography>
                                  <Typography variant='body1'>{paginatedOffers()[selected]?.acteur?.tel_acteur}</Typography>
                                </Box>
                                <Divider />
                                <Box display='flex' sx={{ width: '100%', py: 1 }} justifyContent='space-between' variant='div'>
                                  <Typography variant='body2'>{t('market.offer_act_email')}</Typography>
                                  <Typography variant='body1'>{paginatedOffers()[selected]?.acteur?.email_acteur}</Typography>
                                </Box>
                                <Divider />
                              </div>
                            )}
                          </DialogContent>
                        </Dialog>

                        {(offre.conclus === 1 || moment(offre.dateval) < moment()) ? null : (
                          <Chip label={offre.istatutd} color={offre.istatutd === "En cours" ? "success" : "default"} />
                        )}
                        {(offre.conclus === 0 && moment(offre.dateval) < moment()) && (
                          <Chip label={t("market.Expired")} color="error" />
                        )}
                        {offre.conclus === 1 && (
                          <Chip label={t("market.Conclue")} sx={{ bgcolor: 'blue', color: '#fff' }} color="primary" />
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
                        {offre?.acteur?.nomacteur && <Typography variant='body2'>{t('market.offer_added_by')}</Typography>}
                        <Typography variant='body2'>{offre?.acteur?.nomacteur}</Typography>
                      </Box>
                      <Divider />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
                        <Typography variant='body2'>{t('Type')}</Typography>
                        <Chip label={offreType(offre.typeoffre)} color={(offreType(offre.typeoffre) === t("market.Vente")) ? "primary" : "secondary"} />
                      </Box>
                      <Divider />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
                      <Typography variant='body2'>{t('market.offer_vld_dt_lmt')}</Typography>
                      <Typography variant='body2'>{moment(offre.dateval).format('DD/MMM/YYYY')}</Typography>
                    </Box>
                    <Divider />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
                        <Typography>{offre.prixoffre} {offre.codedev}</Typography>
                        <Typography variant="body2">{` ${t("market.added_at")} : ${moment(offre.datecre).format('DD/M/YYYY')}`}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {offre.conclus === 0 ? <CheckBoxOutlineBlank /> : <Check />}
                          <Typography>{offre.conclus === 0 ? t("market.n_concluded") : t('market.Conclue')}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}

          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, newValue) => setPage(newValue)}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}
