import React, { useEffect, useState } from "react";
import Layout from "../components/ui/layout";
import Baner from "../components/ui/commerce/baner";
import Messages from "../components/ui/commerce/messages";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress } from "@mui/material";
import axios from "../api/without_user_token";

export default function Commerce(){
    useEffect(() => {
        document.title = t('Key messages about trade');
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const [data, setData]   = useState([]);
    const {t, i18n}         = useTranslation('global');
    const [messages, setMessages]          = useState([]);

    

    useEffect(()=>{

        const getDataText = () => {
            axios.get('get_data_text_by_domaine_name', {
                params : { lang: i18n.language, 'name': i18n.language === 'fr' ? 'Commerce' : 'Trade' },
                headers: { 
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                if (response.status === 200) {
                    setData(response.data);
    
                    // if (response.data.length !== 0) {
                    //     const dataset = response.data[0].sub_domain_name;
                    //     const updatedMessages = [];
                    //     for (let i = 0; i < dataset.length; i++) {
                    //         let text = [];
                    //         if (dataset[i].data_text.length > 0) {
                    //             for (let j = 0; j < dataset[i].data_text.length; j++) {
                    //                 text[j] = dataset[i].data_text[j].resume;
                    //             }
                    //         }
                    //         updatedMessages[i] = { actor: dataset[i].name, actor_uuid: dataset[i].uuid, textes: text, link: dataset[i].link }
                    //     }
                    //     setMessages(updatedMessages);
                    // }

                    if (response.data.length !== 0) {
                        const dataset = response.data[0].sub_domain_name;
                        const updatedMessages = [];
                        for (let i = 0; i < dataset.length; i++) {
                            let text = [];
                            if (dataset[i].data_text.length > 0) {
                                for (let j = 0; j < dataset[i].data_text.length; j++) {
                                    text.push({
                                        texte: dataset[i].data_text[j].resume,
                                        link: dataset[i].data_text[j].link
                                    });
                                }
                            }
                            updatedMessages.push({
                                actor: dataset[i].name,
                                actor_uuid: dataset[i].uuid,
                                textes: text,
                                link: dataset[i].link
                            });
                        }
                        setMessages(updatedMessages);
                    }
                }
            }).catch((err) => {
                console.log('Error occur in getDataText: ' + err);
            })
        };
    
        getDataText();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[i18n.language])

    // console.log(messages)
    return(
        <Layout>
            <Baner />
            {data.length === 0 ? (<Box sx={{padding: "2rem 10rem", display: 'flex', justifyContent: 'center'}}> <CircularProgress /> </Box>) : <Messages messages={messages} />}
        </Layout>
    )
}