import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';


const LANGS = [
  {
    value: 'ar',
    label: 'Arabic',
    icon: '/assets/icons/ic_flag_ar.svg',
  },
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg',
  },
  {
    value: 'fr',
    label: 'French',
    icon: '/assets/icons/ic_flag_fr.svg',
  },
  {
    value: 'pt',
    label: 'Portugais',
    icon: '/assets/icons/ic_flag_pt.svg',
  },
];


export default function LanguagePopover() {
  const [open, setOpen] = useState(null);
  const {i18n} = useTranslation('global')
  const USER_LANG                     = navigator.language || navigator.userLanguage;
    const APP_LANG = {
        'fr-FR'	: 'fr',
        'fr'    : 'fr',
        'en-US'	: 'en',
        'en'    : 'en',
        'ar'    : 'ar',
        'pt'    : 'pt',
    };
  const [currentLangIndex, setCurrentLangIndex] = useState(0)

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (lang) => {
		localStorage.setItem('lang', lang);
		i18n.changeLanguage(lang);
    setCurrentLangIndex(LANGS.findIndex(lang => lang.value === i18n.language));
		handleClose();
	}
  
	useEffect(() => {
    const setAppLang = () => {
          if (localStorage.getItem('lang')) {
              i18n.changeLanguage(localStorage.getItem('lang'));
          } else {
              USER_LANG in APP_LANG ? localStorage.setItem('lang', APP_LANG[USER_LANG]) : localStorage.setItem('lang', 'fr');
          }
      }

      setCurrentLangIndex(LANGS.findIndex(lang => lang.value === i18n.language));
      setAppLang();
    }, [USER_LANG, i18n])

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <img src={LANGS[currentLangIndex].icon} alt={LANGS[currentLangIndex].label} />
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 180,
          },
        }}
      >
        {LANGS.map((option, i) => (
          <MenuItem
            key={option.value}
            selected={option.value === i18n.language}
            onClick={() => handleChangeLang(LANGS[i].value)}
            sx={{ typography: 'body2', py: 1 }}
          >
            <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
