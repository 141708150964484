import React, { useEffect, useState } from 'react';
import Layout from '../components/ui/layout';
import { useTranslation } from 'react-i18next';
import Baner from '../components/ui/baner';
import { Box, Button, Card, CardContent, CircularProgress, Container, Typography } from '@mui/material';
import axios from '../api/without_user_token';

function Events() {
    const { t, i18n } = useTranslation('global');
    const [currentType, setCurrentType] = useState(0);
    const [events, setEvents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const types = [t("soon"), t("Passés"), t("day")];
    const pageSize = 5;

    const fetchEvent = () => {
        axios.get('get_all_event', {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.status === 200) {
                const today = new Date();
                const todayDate = today.toISOString().split('T')[0];

                const filtered = [
                    {
                        en: response.data.en.filter(event => new Date(event.start_date) > today),
                        fr: response.data.fr.filter(event => new Date(event.start_date) > today),
                        pt: response.data.pt.filter(event => new Date(event.start_date) > today),
                        ar: response.data.ar.filter(event => new Date(event.start_date) > today)
                    },
                    {
                        en: response.data.en.filter(event => new Date(event.end_date) < today),
                        fr: response.data.fr.filter(event => new Date(event.end_date) < today),
                        pt: response.data.pt.filter(event => new Date(event.end_date) < today),
                        ar: response.data.ar.filter(event => new Date(event.end_date) < today)
                    },
                    {
                        en: response.data.en.filter(event => event.start_date === todayDate || event.end_date === todayDate),
                        fr: response.data.fr.filter(event => event.start_date === todayDate || event.end_date === todayDate),
                        pt: response.data.pt.filter(event => event.start_date === todayDate || event.end_date === todayDate),
                        ar: response.data.ar.filter(event => event.start_date === todayDate || event.end_date === todayDate)
                    }
                ];

                setEvents(filtered);
                console.log(filtered);
            }
        }).catch((err) => {
            console.log(err.message);
        });
    };

    const currentEvents = events[currentType]?.[i18n.language]?.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const totalPages = Math.ceil(events[currentType]?.[i18n.language]?.length / pageSize);

    useEffect(() => {
        fetchEvent();
    }, []);

    return (
        <Layout>
            <Baner title={t("events")} subTitle="" />
            <Container sx={{ py: 4 }}>
                <Box display="flex" gap={1}>
                    {types.map((item, i) => (
                        <Button
                            variant={i === currentType ? 'contained' : 'outlined'}
                            onClick={() => { setCurrentType(i); setCurrentPage(1); }}
                            sx={{ textTransform: 'none' }}
                            key={i}
                        >
                            {item}
                        </Button>
                    ))}
                </Box>
                {(currentEvents !== undefined) ? (
                    <Box sx={{ py: 2 }}>
                        {currentEvents.map((item, i) => (
                            <Card key={i}>
                                <CardContent>
                                    <Typography>{item.title}</Typography>
                                    <Box display="flex">
                                        <Typography>{item.start_date}</Typography>
                                        <Typography>{" "}</Typography>
                                        <Typography> - </Typography>
                                        <Typography>{" "}</Typography>
                                        <Typography>{item.end_date}</Typography>
                                    </Box>
                                    <Typography>{item.describe}</Typography>
                                </CardContent>
                            </Card>
                        ))}

                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                            <Button
                                variant="outlined"
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                            >
                                Précédent
                            </Button>
                            <Typography>Page {currentPage} sur {totalPages}</Typography>
                            <Button
                                variant="outlined"
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                            >
                                Suivant
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <CircularProgress />
                )}
            </Container>
        </Layout>
    );
}

export default Events;