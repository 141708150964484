import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, Collapse, IconButton, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import { useLocation } from 'react-router-dom';

const ResponsiveDrawer = ({ routes, drawerOpen, toggleDrawer, handleDrawerClick }) => {
  const [openSubMenus, setOpenSubMenus] = useState({});
  const location = useLocation();

  useEffect(() => {
    // Ouverture automatique des sous-menus contenant la route active au chargement initial
    const openSubMenusOnLoad = {};

    const openSubMenusRecursively = (menuItems, parentId = '') => {
      menuItems.forEach((item) => {
        const itemId = parentId + item.id;
        if (item.link === location.pathname || (item.sub_menu && item.sub_menu.some(sub => sub.link === location.pathname))) {
          openSubMenusOnLoad[itemId] = true;
        }
        if (item.sub_menu) {
          openSubMenusRecursively(item.sub_menu, itemId);
        }
      });
    };

    openSubMenusRecursively(routes);
    setOpenSubMenus((prev) => ({ ...prev, ...openSubMenusOnLoad }));
  }, [location.pathname, routes]);

  const handleToggleSubMenu = (id) => {
    setOpenSubMenus((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderDrawerSubMenu = (subMenu, parentId = '') => {
    return subMenu.map((item) => {
      const hasSubMenu = item.sub_menu && item.sub_menu.length > 0;
      const itemId = parentId + item.id;
      const isActive = item.link === location.pathname;

      return (
        <div key={itemId}>
          <ListItem button onClick={() => (hasSubMenu ? handleToggleSubMenu(itemId) : handleDrawerClick(item.link))} sx={{ pl: 4 }} selected={isActive}>
            {isActive && <ListItemIcon><CheckIcon /></ListItemIcon>}
            <ListItemText primary={item.name} />
            {hasSubMenu ? (openSubMenus[itemId] ? <ExpandLess /> : <ExpandMore />) : null}
          </ListItem>
          {hasSubMenu && (
            <Collapse in={openSubMenus[itemId]} timeout="auto" unmountOnExit>
              {renderDrawerSubMenu(item.sub_menu, itemId)}
            </Collapse>
          )}
        </div>
      );
    });
  };

  const renderDrawerMenu = (routes) => (
    <List>
      {routes.map((route) => {
        const isActive = route.link === location.pathname;

        return (
          <div key={route.id}>
            <ListItem button onClick={() => (route.sub_menu ? handleToggleSubMenu(route.id) : handleDrawerClick(route.link))} selected={isActive}>
              {isActive && <ListItemIcon><CheckIcon /></ListItemIcon>}
              <ListItemText primary={route.name} />
              {route.sub_menu ? (openSubMenus[route.id] ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItem>
            {route.sub_menu && (
              <Collapse in={openSubMenus[route.id]} timeout="auto" unmountOnExit>
                {renderDrawerSubMenu(route.sub_menu, route.id)}
              </Collapse>
            )}
          </div>
        );
      })}
    </List>
  );

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon sx={{ color: "#fff"}} />
      </IconButton>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {renderDrawerMenu(routes)}
      </Drawer>
    </>
  );
};

export default ResponsiveDrawer;
