import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import {
  Typography,
  Grid,
  Card,
  Container,
  Box,
  Button,
  CardHeader,
  CardContent,
  TextField,
  CardActions,
  alpha,
} from "@mui/material";

import Sent from "../images/sent.gif";


import Layout from "../components/ui/layout";
import Baner from "../components/ui/baner";

import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { ArrowRightAlt, Send } from "@mui/icons-material";
import { useValue } from "../context/ContextProvider";
import { useTranslation } from "react-i18next";
import { green } from "@mui/material/colors";

export default function Contact() {
  const { dispatch } = useValue();
  const form = useRef();

  const [sent, setSent] = useState(false);
  const { t } = useTranslation("global");

  useEffect(() => {
    document.title = "Contacts";
  }, []);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "START_LOADING" });

    emailjs
      .sendForm(
        "service_t0o5tc5",
        "template_jv1uh2i",
        form.current,
        "tOg0qYZZRB6fQEIy2"
      )
      .then(
        (result) => {
          setSent(true);
          dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "success",
              message: "Email envoyé avec succès",
            },
          });
        },
        (err) => {
          dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: err.text,
            },
          });
        }
      )
      .finally(() => {
        form.current.reset(); 
        dispatch({ type: "END_LOADING" });
      });

    form.current.reset();
    dispatch({ type: "END_LOADING" });
  };

  return (
    <Layout>
      <Baner subTitle={t('contact.sub_ttl')} title={t('contact.ttl')} />
      <Box sx={{
        width: '100%',
        height: '100%',
      }} variant='div'>
        <Box sx={{
          width: '100%',
          height: '100%',
          py: 5,
          bgcolor: alpha('#fff', 0.5)
        }}>
          <Container sx={{}}>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Grid
                item
                sx={{ bgcolor: alpha("#157200", 0.3),  }}
                container
                direction="row"
                alignContent="center"
                alignItems="center"
                xs={12}
              >
                <Grid item lg={8} md={6} sm={12} xs={12} container direction='column'>
                <Grid item container sx={{px: 2}}  direction="column">
                  <Grid item>
                    <Typography sx={{ textAlign: 'center', fontWeight: '600' }} variant="h5">
                      {t('contact.ctt_ttl')}
                    </Typography>
                    <Typography sx={{ textAlign: 'center' }} variant="body1">
                      {t('contact.ctt_sub_ttl')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignContent="center"
                    sx={{ py: { lg: "5rem", md: '3rem', sm: '2rem', xs: '1rem' } }}
                    direction="column"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        sx={{
                          fontWeight: "600",
                          textTransform: "none",
                          fontSize: "1rem",
                        }}
                        startIcon={<CallIcon sx={{fontSize: 100}} fontSize="large" />}
                        variant="contained"
                      >
                        +226 30 00 12 47
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        sx={{
                          fontWeight: "600",
                          textTransform: "none",
                          fontSize: "1rem",
                          width: '100%'
                        }}
                        startIcon={<AlternateEmailIcon fontSize="medium" />}
                        variant="outlined"
                      >
                        info@dtrc.com
                      </Button>
                    </Grid>
                    
                  </Grid>
                </Grid>
                <Grid item sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                  <Box display='flex' justifyContent='center' variant='div'>
                    <Typography sx={{mr: 1}} variant="body1">
                      {t('contact.ctt_form_idct')}
                    </Typography>
                    <ArrowRightAlt fontSize="medium" color="primary" />
                  </Box>
                    </Grid>
                </Grid>
                
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Card elevation={9} sx={{ width: "100%", border: '3px solid', borderColor: "#157200" }}>
                    {!sent ? (
                      <Box>
                        <CardHeader sx={{fontWeight: '700'}} title={t('contact.form_ttl')} />
                        <form ref={form} onSubmit={handleEmailSubmit}>
                          <CardContent>
                            <Grid
                              container
                              justifyContent="space-between"
                              sx={{ height: "20rem" }}
                              direction="column"
                            >
                              <Grid item>
                                <TextField
                                  variant="standard"
                                  required
                                  id="standard-basic"
                                  type="text"
                                  fullWidth
                                  label={t("contact.ctt_form_name")}
                                  name="user_name"
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  variant="standard"
                                  required
                                  type="email"
                                  id="standard-basic"
                                  fullWidth
                                  label={t('contact.ctt_form_email')}
                                  name="user_email"
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  variant="standard"
                                  required
                                  id="standard-basic"
                                  type="text"
                                  fullWidth
                                  label={t('contact.ctt_form_subject')}
                                  name="user_subject"
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  id="outlined-multiline-static"
                                  fullWidth
                                  multiline
                                  rows={4}
                                  label="Message "
                                  name="message"
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                            >
                              <Grid item>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  sx={{ mr: "0.5rem", textTransform: 'none' }}
                                  startIcon={<Send />}
                                >
                                  {t('contact.ctt_form_snd')}
                                </Button>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </form>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        sx={{ width: "100%", height: "100%" }}
                        flexDirection="column"
                        alignContent="center"
                      >
                        <img
                          src={Sent}
                          style={{ height: 200, width: 200, margin: "auto auto" }}
                          alt="Sent"
                        />
                        <Button
                          sx={{
                            fontWeight: "600",
                            textTransform: "none",
                            fontSize: "1.75rem",
                          }}
                          href="/"
                        >
                          {t("menu.home")}
                        </Button>
                      </Box>
                    )}
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Layout>
  );
}
