import React, { useEffect, useState } from 'react'
import Layout from '../components/ui/layout'
import Baner from '../components/ui/baner'
import { Box, CircularProgress, Container } from '@mui/material'
import axios from '../api/without_user_token'
import { useTranslation } from 'react-i18next'
import Bg from "../images/form_bg.png"

function CapVert() {
    const [content, setContent] = useState()
    const { t, i18n } = useTranslation('global');

    const fetchContent = () => {
        axios.get('get_all_country_text', {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.status === 200) {
                const filtered = {
                    en: response.data?.en.filter(item => item.country_name === "Cap Vert"),
                    fr: response.data?.fr.filter(item => item.country_name === "Cap Vert"),
                    ar: response.data?.ar?.filter(item => item.country_name === "Cap Vert"),
                    pt: response.data?.pt?.filter(item => item.country_name === "Cap Vert"),
                }
                setContent(filtered)
            }
        }).catch((err) => {
            console.log(err.message)
        })
    }

    useEffect(() => {
        fetchContent()
    }, [])
    return (
        <Layout>
            <Baner title={t("menu.cv")} subTitle="" />
            <Box sx={{ width: '100%', backgroundImage: `url(${Bg})`, backgroundSize: 'cover', backgroundPosition: 'top', backgroundRepeat: 'no-repeat' }}>
                <Container sx={{ py: 4 }}>
                    {content !== undefined ? (
                        <div dangerouslySetInnerHTML={{ __html: content[i18n.language][0].content }} />
                    ) : (
                        <CircularProgress />
                    )}
                </Container>
            </Box>
        </Layout>
    )
}

export default CapVert