import React, { useEffect } from "react";
import { Typography } from "@mui/material";

import Layout from "../components/ui/layout";


export default function Help(){

    useEffect(() => {
        document.title = 'Aides';
      }, []);

    return(
        <Layout>
            <Typography>Help</Typography>
        </Layout>
    )
}