/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Grid, Button, Popover, Typography,
    AppBar, Toolbar,
    Box, Avatar, Container
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, useNavigate } from 'react-router-dom';
import Arrow from '@mui/icons-material/ArrowDropDownSharp';
import axios from '../../api/without_user_token';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import LogoCilss from "../../images/cilssdef.png";
import Cedeao from "../../images/cedeao.png";
import Uemoa from "../../images/uemoa.png";
import CilssCard from "../../images/cilssCard3.png"
import Logo from "../../images/logodef.png"
import mPhoto from "../../images/mobille_app.jpg"
import sd3c from "../../images/sd3c.png"

import praps from "../../images/praps2.png"
import { keyframes } from '@emotion/react'

import { InstallMobile } from '@mui/icons-material';
// import Navbar from './Navbar';
import NavigationMenu from './NavigationMenu';
import ResponsiveDrawer from './ResponsiveDrawer';

const marquee = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

export default function Header() {
    const matchesMD = useMediaQuery("(max-width: 1200px)");
    const [t, i18n] = useTranslation('global');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ipAdress, setIpAdress] = useState('');
    const [geoInfo, setGeoInfo] = useState({});
    const [countryNumber, setCountryNumber] = useState();
    const [sub, setSub] = useState(null);
    const [events, setEvents] = useState()

    const [drawerOpen, setDrawerOpen] = useState(false);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const USER_LANG = navigator.language || navigator.userLanguage;
    const APP_LANG = {
        'fr-FR': 'fr',
        'fr': 'fr',
        'en-US': 'en',
        'en': 'en',
        'ar': 'ar',
        'pt': 'pt',
    };


    const navigate = useNavigate()

    const COUNTRY_NUMBER = {
        BF: 3488,
        GM: 1616,
        SN: 200800,
        ML: 36065,
        TD: 1324
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverClose = () => {
        setSub(null);
    };

    const handleChangeLang = (lang) => {
        localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);
        handleClose();
    }

    const routes = [
        { name: t("menu.home"), id: "/", link: "/" },
        {
            name: t("menu.audio_visuel"), id: "audiovisu", link: "/#", sub_menu: [
                { name: t("menu.affiches"), id: "affiches", link: "/audiovisu/affiches" },
                { name: t("menu.audios"), id: "audios", link: "/audiovisu/audios" },
                { name: t("menu.videos"), id: "videos", link: "/audiovisu/videos" },
                { name: t("menu.ref"), id: "references", link: "/audiovisu/references" },

            ]
        },
        {
            name: t('menu.resume_textes'), id: "textes", link: "/#", sub_menu: [
                {
                    name: t("menu.organisations"), id: "organisations", link: "/#", sub_menu: [
                        { name: t("menu.CEDEAO"), id: "cedeao", link: "/textes/organisations/cedeao" },
                        { name: t("menu.UEMOA"), id: "uemoa", link: "/textes/organisations/uemoa" },
                        { name: t("menu.OHADA"), id: "ohada", link: "/textes/organisations/ohada" },
                        { name: t("menu.ZLECAF"), id: "zlecaf", link: "/textes/organisations/zlecaf" },
                    ]
                },
                {
                    name: t("menu.countries"), id: "country", link: "/#", sub_menu: [
                        { name: t("menu.bf"), id: "bf", link: "/textes/country/bf" },
                        { name: t("menu.ml"), id: "ml", link: "/textes/country/ml" },
                        { name: t("menu.ne"), id: "ne", link: "/textes/country/ne" },
                        { name: t("menu.td"), id: "td", link: "/textes/country/td" },
                        { name: t("menu.bj"), id: "bj", link: "/textes/country/bj" },
                        { name: t("menu.ci"), id: "ci", link: "/textes/country/ci" },
                        { name: t("menu.gm"), id: "gm", link: "/textes/country/gm" },
                        { name: t("menu.gn"), id: "gn", link: "/textes/country/gn" },
                        { name: t("menu.gw"), id: "gw", link: "/textes/country/gw" },
                        { name: t("menu.mr"), id: "mr", link: "/textes/country/mr" },
                        { name: t("menu.sn"), id: "sn", link: "/textes/country/sn" },
                        { name: t("menu.tg"), id: "tg", link: "/textes/country/tg" },
                        { name: t("menu.cv"), id: "cv", link: "/textes/country/cv" },
                    ]
                },
            ]
        },
        {
            name: t("menu.appliquer"), id: "formproc", link: "/#", sub_menu: [
                { name: t("menu.conditions"), id: "formalities", link: "/formproc/formalities" },
                { name: t("menu.guides"), id: "procedures", link: "/formproc/procedures" },
            ]
        },
        // { name: t("menu.medias"), id: "media", link: "/media" },
        {
            name: t("menu.documenthec"), id: "resources", link: "/#", sub_menu: [
                { name: t("menu.procedure_and_commerciale"), id: "procedureComm", link: "/resources/procedures-commerciales" },
                { name: t("menu.pubs"), id: "bulletin", link: "/resources/bulletin-information" },
                { name: t("menu.doccommerce"), id: "doccommerce", link: "/resources/commerce" },

                { name: t("menu.events"), id: "report", link: "/resources/report" },

            ]
        },
        // { name: t("menu.documenthec"), id: "resources", link: "/resources" },
        // { name: t("menu.Contacts"), id: "contacts", link: "/contacts" },
    ]


    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleDrawerClick = (link) => {
        setDrawerOpen(false);
        navigate(link);
    };


    const setAppLang = () => {
        if (localStorage.getItem('lang')) {
            i18n.changeLanguage(localStorage.getItem('lang'));
        } else {
            USER_LANG in APP_LANG ? localStorage.setItem('lang', APP_LANG[USER_LANG]) : localStorage.setItem('lang', 'fr');
        }
    }

    const fetchEvent = () => {
        axios.get('get_all_event', {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.status === 200) {
                let data = response.data;
                const lastElement = data[data.length - 1];
                setEvents(lastElement);
            }
        }).catch((err) => {
            console.log(err.message)
        })
    }

    useEffect(() => {
        fetchEvent()
    }, [])

    useEffect(() => {
        const getVisitorIp = async () => {
            try {
                const response = await fetch('https://api.ipify.org');
                const data = await response.text();
                setIpAdress(data)
            } catch (err) { }
        }

        const fetchIpInfo = async () => {
            try {
                const response = await fetch(`https://geolocation-db.com/json/345dd3d0-b984-11ee-af01-4784ec48a61d/${ipAdress}`);
                const data = await response.json();
                setGeoInfo(data)
            } catch (err) { }
        }

        const getCountryNumber = () => {
            geoInfo.country_code in COUNTRY_NUMBER ? setCountryNumber(COUNTRY_NUMBER[geoInfo.country_code]) : setCountryNumber(-1);
        }

        setAppLang();
        getVisitorIp();
        if (ipAdress !== '') fetchIpInfo()
        if (geoInfo.length !== 0) getCountryNumber()
    }, [ipAdress, geoInfo])

    return (
        <Grid container direction="column" justifyContent="space-between" alignItems="center">
            <Grid container direction="row" sx={{
                px: { xs: '1rem', sm: '2rem', md: '5rem', lg: '10rem' }, py: '0.1rem', borderBottom: '2px solid', borderBottomColor: '#FDF50D', backgroundColor: "#157200"
            }}
                justifyContent="space-between" alignContent='center' alignItems="center">
                <Grid container item xs={12} sm={12} md={4} lg={3} alignContent='center' alignItems='center' justifyContent="space-between" direction="row">
                    <Grid item>
                        <Button href='https://agrhymet.cilss.int/' variant='text' sx={{ color: '#fff' }}>AGRHYMET CCR-AOS</Button>
                    </Grid>
                    <Grid item sx={{ color: "#fff" }}>
                        |
                    </Grid>
                    <Grid item>
                        <Button href='https://www.cilss.int/' variant='text' sx={{ color: '#fff' }}>CILSS</Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        aria-describedby={id}
                        sx={{ color: '#fff' }}
                        onClick={handleClick}
                        variant="text" >
                        {t(`lang.lang_${i18n.language}`)}
                        <Arrow />
                    </Button>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} >
                        <Grid container direction="column">

                            <Grid item>
                                <Button sx={{
                                    width: '100%', color: "#157200", fontWeight: "600"
                                }} disabled={i18n.language === 'fr'}
                                    variant={i18n.language === 'fr' ? 'contained' : 'text'}
                                    onClick={() => handleChangeLang('fr')} >
                                    Français
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button sx={{
                                    width: '100%', color: "#157200", fontWeight: "600"
                                }} disabled={i18n.language === 'en'}
                                    variant={i18n.language === 'en' ? 'contained' : 'text'}
                                    onClick={() => handleChangeLang('en')} >
                                    English
                                </Button>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid>
            </Grid>

            <Grid container item
                // sx={{
                //     px: {
                //         xs: '0.5rem', sm: '1rem', md: '2.5rem', lg: '5rem', backgroundImage: `url(${Baner})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'
                //     }
                // }}
                alignItems="center"
                alignContent='center'
                direction={{ xs: 'column', sm: "row" }} justifyContent="space-between">
                <Grid item spacing={2} xs={12} md={2} alignContent="center" alignItems="center" sm={5} lg={2} container>
                    <Grid xs={6} item >
                        <Box component={Link} to="/" variant="div" display='flex' justifyContent="space-between" flexDirection="column" sx={{ textDecoration: 'none', width: "150px" }} alignItems="center" alignContent="center">
                            <img style={{ width: "70%" }} src={Logo} alt="Logo du projet" />
                        </Box>
                    </Grid>
                    <Grid xs={6} item>
                        <img style={{ width: "150px" }} src={LogoCilss} alt="Logo du CILSS" />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={3} sx={{ pl: '1rem' }}>
                    <marquee style={{ width: '18rem', marginBottom: '2rem', fontWeight: '600', textTransform: 'uppercase', PaddingLeft: '2rem' }}>
                        {t("header.Welcome to the platform for disseminating regional trade texts and sharing trade opportunities")}
                    </marquee>

                    {(countryNumber !== -1) && (
                        <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center', mb: '1rem', bgcolor: '#007416', borderRadius: 50, width: '19rem' }}>
                            <Avatar sx={{ bgcolor: '#fff', width: 30, height: 30, zIndex: 2, ml: 1 }}>
                                <LocalPhoneRoundedIcon sx={{ color: '#007416' }} />
                            </Avatar>

                            <Button variant='text' sx={{ width: '100%', fontWeight: '600', textTransform: 'none', ml: -5, }} href={`tel:${countryNumber}`} >
                                <Typography sx={{ bgcolor: '#007416', color: '#fff', p: '0.3rem', pr: 2, borderRadius: 50, ml: 5, zIndex: 1 }}>
                                    {t('header.ivr_call')}
                                </Typography>

                                <Typography sx={{ bgcolor: '#fff', py: '0.2rem', pr: '0.4rem', ml: -2, pl: 3, borderRadius: 50, letterSpacing: 4, fontWeight: '600' }}>
                                    {countryNumber}
                                </Typography>
                            </Button>
                        </Box>
                    )}

                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Box variant="div" display="flex" justifyContent="center" alignContent="center" alignItems="center" flexDirection="column">
                        <img src={mPhoto} style={{ width: '25%' }} alt='App mobile' />
                        <Button href="https://play.google.com/store/apps/details?id=com.cilss.app&hl=fr&gl=US" target='_blank' startIcon={<InstallMobile />}
                            variant='contained' sx={{ textTransform: 'none', textDecoration: 'none', fontWeight: '600', color: 'primary', borderRadius: 20 }} >
                            {t("header.download_mobile_app")}
                        </Button>
                    </Box>
                </Grid>

                <Grid item container xs={12} sm={12} md={12} lg={3} sx={{
                    paddingRight: "15px", backgroundImage: `url(${CilssCard})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center right', py: "2rem"
                }} alignItems="flex-end" direction='column'>
                    <Box display="flex" flexDirection={{ xs: "row" }} justifyContent="space-between" alignItems="center">
                        <img style={{ width: "24%", height: "auto" }} src={Cedeao} alt="Logo du CEDEAO" />
                        <img style={{ width: "24%", height: "auto" }} src={Uemoa} alt="Logo de l'UEMOA" />
                        <img style={{ width: "24%", height: "auto" }} src={sd3c} alt="Logo de SD3C" />
                        <img style={{ width: "24%", height: "auto" }} src={praps} alt="Logo du PRAPS2" />
                    </Box>
                </Grid>
            </Grid>

            <Container>
                <Box display="flex" flexDirection="row" alignContent="center" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }} >
                    <Typography sx={{ px: 2 }}>{t("header.events")}</Typography>
                    <Box
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            boxSizing: 'border-box',
                        }}
                    >
                        <Box
                            component="span"
                            sx={{
                                display: 'inline-block',
                                paddingLeft: '100%',
                                animation: `${marquee} 40s linear infinite`,
                            }}
                        >
                            {events !== undefined ? (
                                events[i18n.language].map((evmt, i) => (
                                    <Box display="flex" alignItems="center" flexDirection="row" key={i}>
                                        <Box sx={{ bgcolor: '#157200', px: 1, mr: 2 }} alignItems="center" display="flex" flexDirection="column">
                                            <Typography variant='body2' sx={{ color: 'white' }}>
                                                {t("header.from")}
                                            </Typography>
                                            <Typography variant='body2' sx={{ color: 'white', fontWeight: '700' }}>{evmt.start_date}</Typography>
                                        </Box>
                                        <Box sx={{ bgcolor: '#FDF50D', px: 1, mr: 2 }} alignItems="center" display="flex" flexDirection="column">
                                            <Typography variant='body2'>
                                                {t("header.to")}
                                            </Typography>
                                            <Typography sx={{ fontWeight: '700' }} variant='body2'>{evmt.end_date}</Typography>
                                        </Box>
                                        <Typography sx={{ fontWeight: '700' }}>
                                            {evmt.describe}
                                        </Typography>
                                    </Box>
                                ))
                            ) : undefined}
                        </Box>
                    </Box>
                    <Button sx={{textTransform: "none"}} variant='contained' href='/events'>{t("header.all")}</Button>
                </Box>
            </Container>

            <Grid container item alignItems='center' justifyContent='space-between' alignContent='center'
                sx={{ borderTopColor: '#FDF50D', borderBottomColor: '#157200', backgroundColor: '#fff' }} >
                <AppBar onMouseLeave={routes[1].sub_menu && handlePopoverClose} color='transparent' sx={{
                    borderBottom: 2,
                    borderTop: 2,
                    borderTopColor: '#FDF50D',
                    borderBottomColor: '#157200',
                    bgcolor: '#157200',
                    px: '10rem'
                }} elevation={0} position='sticky'>
                    <Container>
                        <Toolbar sx={{
                            bgcolor: 'primary'
                        }} disableGutters>
                            {matchesMD ? (<ResponsiveDrawer
                                routes={routes}
                                drawerOpen={drawerOpen}
                                toggleDrawer={toggleDrawer}
                                handleDrawerClick={handleDrawerClick}
                            />) :
                                // tabs
                                (<Box>
                                    {/* {tabs} */}
                                    <NavigationMenu routes={routes} />
                                </Box>
                                )
                            }
                            {/* {matchesMD && (
                                <IconButton onClick={() => setOpenDrawer(true)} >
                                    <MenuIcon fontSize='large' sx={{ color: '#fff' }} />
                                </IconButton>
                            )
                            } */}
                        </Toolbar>
                    </Container>
                </AppBar>
            </Grid>
        </Grid>
    )
}