import React from 'react';
import { Navigate } from 'react-router-dom';

const Protected = ({ children }) => {
   let isAuth = false
   const currentUser = JSON.parse(localStorage.getItem('currentUser'))
  isAuth = Boolean(currentUser);
  return isAuth ? children : <Navigate to="/admin/login" replace={true} />
};

export default Protected;
